<template>
  <FieldsList
    aut-application-fields
    :domain="true"
    @update="saveFieldsSettingsToDomain"
  />
</template>
<script>
import FieldsList from "@/views/user/UserPreferences/FieldsList.vue";
import { mapActions } from "vuex";

export default {
  name: "SettingsFields",
  components: {
    FieldsList,
  },
  props: {
    definition: {
      type: Object,
    },
  },
  methods: {
    ...mapActions(["saveFieldsSettingsToDomain"]),
  },
};
</script>
