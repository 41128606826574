<template>
  <v-row dense>
    <v-col cols="12" class="smaller mb-0 pb-0">
      <v-text-field
        label="Filter Fields"
        outlined
        hide-details
        dense
        prepend-icon="mdi-magnify"
        aut-role-search
        placeholder="Search"
        clearable
        v-model="searchValue"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-tabs v-model="tabModel">
        <v-tab
          v-for="field in filteredFields"
          :key="field.id"
          :aut-tab="field.id"
          >{{ field.label }}</v-tab
        >
        <v-tab-item
          v-for="field in filteredFields"
          :key="field.label"
          :aut-tab-item="field.id"
        >
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <div class="smaller">
                  <v-autocomplete
                    label="Template"
                    dense
                    small
                    v-model="selectedTemplate"
                    :items="fieldTemplates"
                    item-text="label"
                    item-value="id"
                    @change="selectTemplate"
                  />
                </div>
                <FieldSettings
                  v-if="definition"
                  :field="activeField"
                  :definition="definition"
                  @change="saveChanges"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
      </v-tabs>
    </v-col>
  </v-row>
</template>
<script>
import { clone } from "@/util";
import { getFieldDefinitions } from "@/components/fields/util.js";
import FieldSettings from "@/components/fields/Field/FieldSettings/FieldSettings.vue";
import { mapGetters } from "vuex";
import { defaultsDeep } from "lodash";

const debug = require('debug')('atman.components.fields_list')  // eslint-disable-line

export default {
  name: "FieldsList",
  components: {
    FieldSettings,
  },
  props: {
    domain: Boolean,
  },
  data() {
    return {
      searchValue: null,
      tabModel: 0,
      selectedFieldIndex: 0,
      activeField: null,
      fields: [],
      definition: null,
      fieldTemplates: [],
      selectedTemplate: null,
    };
  },
  computed: {
    ...mapGetters(["domainFieldSettings"]),
    filteredFields() {
      let result = [...this.fields];
      if (this.searchValue) {
        result = result.filter(
          (field) =>
            field.label.toLowerCase().indexOf(this.searchValue.toLowerCase()) !=
            -1
        );
      }
      return result;
    },
  },
  watch: {
    filteredFields() {
      const fieldID = this.filteredFields[this.tabModel].id;
      this.selectField(fieldID);
    },
    tabModel() {
      const fieldID = this.filteredFields[this.tabModel].id;
      this.selectField(fieldID);
    },
  },
  async mounted() {
    const component = this;
    component.fields = getFieldDefinitions().filter(
      (field) => !!field.attributes
    );
    debug(`fields`, component.fields);

    component.selectField();
  },
  methods: {
    selectTemplate() {
      this.definition = null;
      const templateID = this.selectedTemplate;
      let templateValue = this.fieldTemplates.find(
        ({ id }) => id == templateID
      )?.value;

      if (!templateValue) {
        console.error(`No template found. So not showing a preview`);
        return;
      }

      if (this.domain) {
        const domainSettings = this.domainFieldSettings[templateValue.type];
        templateValue = defaultsDeep(
          {},
          { display: domainSettings },
          templateValue
        );
      }
      // TODO will be buggy if default template has some attributes/styles
      this.$nextTick(() => {
        this.definition = clone(templateValue);
      });
    },
    selectField(fieldId) {
      this.definition = null;
      this.activeField = null;
      this.fieldTemplates = [];
      this.selectedTemplate = null;
      const field = fieldId
        ? this.fields.find(({ id }) => id == fieldId)
        : this.fields[0];
      this.activeField = clone(field);
      this.fieldTemplates = field.templates || [];
      // Get the default Template for this field type
      let defaultTemplate = this.fieldTemplates?.[0];
      if (defaultTemplate?.id) {
        this.selectedTemplate = defaultTemplate.id;
        this.selectTemplate();
      }
    },
    saveChanges(field) {
      this.activeField = clone(field);
      const type = field.type;
      const fields = {};
      fields[type] = {
        attributes: {},
      };
      field.attributes.forEach((attribute) => {
        fields[type].attributes[attribute.name] = attribute.value;
      });
      this.$emit("update", fields);
    },
  },
};
</script>
